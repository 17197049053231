import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const termsContainerStyle = {
    backgroundColor: '#a4161a',
    color: '#f5f3f4',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
  };

  return (
    <div>
      <Helmet>
        <title>Terms and Conditions & Privacy Policy - FastOps</title>
        <meta
          name="description"
          content="Read our Terms and Conditions and Privacy Policy to understand how we handle your personal information and the rules governing your use of our services."
        />
      </Helmet>
      <section className="py-10 bgc">
        <div className="container pt-14 min-h-screen">
          <h2 className="text-headingColor text-center font-[600] text-[30px] sm:text-[35px]">
            Terms and Conditions & Privacy Policy
          </h2>
          <div style={termsContainerStyle}>
            {/* Terms and Conditions Section */}
            <h3 className="text-headingColor font-[600] text-[25px] sm:text-[30px] mt-6 mb-4">Terms and Conditions</h3>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Agreement Overview:</strong> These Terms of Service ("Terms" or "Agreement") represent an
              understanding between FastOps LLC ("we," "us," or "our") and the user ("User" or "you"). These Terms
              govern your use of our website and any services we provide.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Links to Other Websites:</strong> Our website may include links to external sites. We do not
              endorse, approve, or take responsibility for the content of those sites. Please review the privacy policies
              and terms of use of any external site you visit.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Communication:</strong> We may contact you through SMS, email, or phone to provide updates or
              promotional offers. You can opt-out of promotional messages at any time by replying 'STOP' to such
              messages.
            </li>

            {/* Privacy Policy Section */}
            <h3 className="text-headingColor font-[600] text-[25px] sm:text-[30px] mt-6 mb-4">Privacy Policy</h3>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Commitment to Privacy:</strong> Your privacy is important to us. This Privacy Policy explains how
              we collect, use, and protect your personal data when you use our website or services.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Information We Collect:</strong> We may collect personal information such as your name, email,
              phone number, and other details when you voluntarily provide them, for example, through forms on our site
              or service sign-ups.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>How We Use Your Information:</strong> Collected information will be used to:
              <ul className="list-disc ml-4">
                <li>Improve your experience on our website.</li>
                <li>Respond to inquiries and provide services.</li>
                <li>Send you relevant promotional materials or updates if you opt-in.</li>
              </ul>
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Data Security:</strong> We have implemented security measures to protect your personal
              information. However, we cannot guarantee that data transmitted over the internet is completely secure.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Third-Party Links:</strong> Our website may link to third-party sites. We are not responsible for
              the privacy practices of these external sites. Please refer to their policies when visiting them.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Your Rights:</strong> You have the right to access, correct, or delete your personal data. You can
              also opt-out of receiving promotional messages by replying 'STOP' to SMS or by using the unsubscribe link
              in emails.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Opt-Out Options:</strong> You can opt-out of promotional messages:
              <ul className="list-disc ml-4">
                <li>SMS: Reply 'STOP' to any promotional text to stop receiving SMS messages.</li>
                <li>Email: Click the 'Unsubscribe' link at the bottom of any promotional email to stop receiving emails.</li>
              </ul>
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Changes to this Policy:</strong> FastOps LLC reserves the right to update this Privacy Policy.
              Changes will be posted here with an updated "Last Revised" date.
            </li>
            <li className="gap-2 text-[#f5f3f4] font-[500] text-[20px] leading-7 text-justify mb-4">
              <strong>Contact Us:</strong> If you have any questions about this policy or your personal data, please
              contact us at [Insert contact info].
            </li>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
